import i18next from "i18next";

i18next.init({
  lng: typeof window !== "undefined" ? localStorage.getItem("i18nextLng") || "en" : "en",
  fallbackLng: typeof window !== "undefined" ? localStorage.getItem("i18nextLng") || "en" : "en",
  resources: {
    ua: {
      translations: require("../locales/ua/translations.json"),
    },
    en: {
      translations: require("../locales/en/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

i18next.languages = ["ua", "en"];

export default i18next;
